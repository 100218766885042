import { DropdownBrand, PlatformTabView, PosSummaryView, PrimaryButton, SkeltonButton, Toggle, } from '@/components';
import { PlatformTypeDecorator, PosTypeDecorator } from '@/decorators';
import {
  buildItemPosItems,
  findCandidatePosItemsForItem,
  Item,
  ItemPosItem,
  mapItemPosItemsWithItemPosItems,
  Platform,
  PlatformType,
  platformTypesToShow,
  PosItem,
  posItemToItemPosItems,
  PosPlatformType,
  Similarity,
  unIntegratedPosItemCountOfPlatform,
} from '@/entities';
import { usePlatformTypeQuery } from '@/utils';
import { useEffect, useState } from 'react';
import { PosItemList } from '@/components/lists/PosItemList';
import { useSelectableStoreResources } from '@/utils/hooks/useSelectableStoreResources';
import styles from '@/styles/partials/forms/pos/PosMenuForm.module.scss';
import { Modal } from '@/components/layouts/Modal';
import { ScrollablePosSelector } from '@/components/controls/ScrollablePosSelector';

interface Props {
  storeId: string;
  posType: PosPlatformType | undefined;
  allPlatforms: Platform[];
  items: Omit<Item, 'createTime' | 'updateTime'>[];
  posItems: PosItem[];
  similarityMap: Record<string, Similarity>;
  onNext: (items: PosItem[]) => void;
  onPrev: () => void;
  onChangePickupPosItemEnabled: (
    brandId: string,
    platformType: PlatformType,
    enabled: boolean,
  ) => void;
}

export const PosMenuForm: React.FC<Props> = ({
  storeId,
  posType,
  allPlatforms,
  items,
  posItems,
  similarityMap,
  onNext,
  onPrev,
  onChangePickupPosItemEnabled
}: Props) => {
  const queriedPlatformTypes = usePlatformTypeQuery();

  const [itemPosItems, setItemPosItems] = useState<ItemPosItem[] | undefined>(undefined);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState<string>();
  const [fulfillment, setFullfillment] = useState<'delivery' | 'pick_up'>();
  const [useDisplayOnlyItemsWithPriceMismatch, setUseDisplayOnlyItemsWithPriceMismatch] = useState<boolean>(false);
  const [useDisplayOnlyUnselected, setUseDisplayOnlyUnselected] = useState<boolean>(false);

  const {
    store,
    storeBrands,
    brandPlatforms,
    selectedBrand,
    selectedPlatform,
    selectBrandId,
    selectPlatformType
  } = useSelectableStoreResources(storeId, allPlatforms);

  const platformItems = items
    ?.filter((item) => item.brandId === selectedBrand?.id && item.platformType === selectedPlatform?.type)
    ?? [];

  const usePickupPosItem = selectedPlatform?.pickupPosItemEnabled ?? false;
  const platformName = selectedPlatform && new PlatformTypeDecorator(selectedPlatform.type).name();
  const statePosItems = mapItemPosItemsWithItemPosItems(posItems, itemPosItems);
  const selectedItem = items.find(item => item.id === selectedItemId);

  const integratedPlatforms = platformTypesToShow(brandPlatforms.map(platform => platform.type), queriedPlatformTypes)
    .map(platformType => ({
      platformType,
      platform: brandPlatforms?.find(platform => platform.type === platformType),
      operation: undefined
    }));

  const unIntegratedItemCount = allPlatforms
    ?.map(platform => unIntegratedPosItemCountOfPlatform(platform, items, statePosItems))
    .reduce((prev, current) => prev + current, 0)
    ?? 0;

  useEffect(() => {
    if (itemPosItems || !posItems || posItems.length === 0) return;

    setItemPosItems(posItems.map(posItemToItemPosItems).flat());
  }, [itemPosItems, setItemPosItems, posItems]);

  const selectItemPosItem = (item: Item, fulfillment: 'delivery' | 'pick_up', posItemId?: string) => {
    if (!itemPosItems) return;

    const updated = itemPosItems
      .map(itemPosItem => itemPosItem.type === fulfillment && itemPosItem.itemId === item.id ? undefined : itemPosItem)
      .filter((itemPosItem): itemPosItem is ItemPosItem => !!itemPosItem);

    posItemId
      ? setItemPosItems([...updated, { itemId: item.id, type: fulfillment, posItemId }])
      : setItemPosItems(updated);
  }

  const autoSelectItemPosItems = () => {
    if(!itemPosItems) return

    const autoSelectedItemPosItems = items
      .flatMap(item => ({
        item,
        posItem: findCandidatePosItemsForItem(item, posItems as PosItem[], similarityMap)[0]
      }))
      .filter(({ item, posItem }) => posItem && !itemPosItems.some(itemPosItem => itemPosItem.itemId === item.id))
      .flatMap(({ item, posItem }) => buildItemPosItems(item, posItem, usePickupPosItem))

    if(autoSelectedItemPosItems.length === 0) {
      alert('マッチング率の高い商品・オプションが見つかりませんでした。')
      return;
    }

    setItemPosItems([...itemPosItems, ...autoSelectedItemPosItems])
    alert(`${autoSelectedItemPosItems.length}件のマッチング率の高い商品・オプションが見つかりました。`)
  }

  const onSelectPlatform = (platformType: PlatformType) => {
    if (!brandPlatforms.map(platform => platform.type).includes(platformType)) return;

    selectPlatformType(platformType);
  };

  const onChangePickupPosItemEnabledToggle = (enabled?: boolean) => {
    if (!selectedBrand || !selectedPlatform) return;

    onChangePickupPosItemEnabled(selectedBrand.id, selectedPlatform.type, !!enabled);
  };

  const onOpenModal = (item: Item, fulfillment: 'delivery' | 'pick_up') => {
    setIsOpen(true);
    setSelectedItemId(item.id);
    setFullfillment(fulfillment)
  }

  const onSelect = (item: Item, fulfillment: 'delivery' | 'pick_up', posId: string | undefined) => {
    selectItemPosItem(item, fulfillment, posId)
    setIsOpen(false)
  }

  return (
    <>
      <Modal {...{ isOpen, onClose: () => setIsOpen(false) }}>
        {selectedItem && fulfillment &&
          <ScrollablePosSelector {...{
            item: selectedItem,
            posItems: statePosItems,
            fulfillment,
            similarityMap,
            onSelect
          }}/>
        }
      </Modal>
      <div className={styles.wrapper}>
        <section>
          <PosSummaryView {...{ store, posName: posType && new PosTypeDecorator(posType).name() }} />
          <h2>プラットフォームとPOSのメニューを連携してください</h2>
          <p className={styles.description}>プラットフォームのメニューに対し、連携するPOSのメニューを選択し紐付けを行ってください。</p>
          <WarningView {...{ count: unIntegratedItemCount }} />
          <div className={styles.container}>
            {/* ToDo: Coding properly */}
            <div className={styles.dropDown}>
              <DropdownBrand
                options={storeBrands}
                currentIndex={storeBrands.findIndex(brand => brand.id === selectedBrand?.id)}
                onSelect={selectBrandId} />
            </div>
            <div className={styles.box}>
              <div className={styles.subtitleButton} onClick={() => autoSelectItemPosItems()}>
                <img src="/icon/tie.svg" />
                <div className={styles.title}>マッチング率が高い商品・オプションを一括選択</div>
              </div>
              <p>まだ選択されていないすべてのブランドの商品・オプションを一括選択します</p>
            </div>
          </div>
          <fieldset className={styles.fieldset}>
            <input
              type='checkbox'
              id={'displayOnlyUnselected'}
              className={styles.checkbox}
              checked={useDisplayOnlyUnselected}
              onChange={() => setUseDisplayOnlyUnselected(!useDisplayOnlyUnselected)}
            />
            <label htmlFor='displayOnlyUnselected'>未選択のみ表示</label>
            <input
              type='checkbox'
              id='displayOnlyItemsWithPriceMismatch'
              className={styles.checkbox}
              checked={useDisplayOnlyItemsWithPriceMismatch}
              onChange={() => setUseDisplayOnlyItemsWithPriceMismatch(!useDisplayOnlyItemsWithPriceMismatch)}
            />
            <label htmlFor='displayOnlyItemsWithPriceMismatch'>価格不一致のみ表示</label>
          </fieldset>
          <PlatformTabView {...{
            integratedPlatforms,
            selectedPlatformType: selectedPlatform?.type,
            items,
            posItems: statePosItems,
            onClick: onSelectPlatform
          }} />
          {/* TODO: DO NOT hard coding the PlatformTypes */}
          {selectedPlatform && [PlatformType.MENU, PlatformType.UBEREATS].includes(selectedPlatform?.type) && (
            <div className={styles.pickupToggle}>
              <p>テイクアウト用金額：{usePickupPosItem ? 'オン' : 'オフ'}</p>
              <Toggle checked={usePickupPosItem} onChange={onChangePickupPosItemEnabledToggle} />
            </div>
          )}
          <PosItemList {...{
            posItems: statePosItems,
            items: platformItems,
            useDisplayOnlyItemsWithPriceMismatch,
            useDisplayOnlyUnselected,
            usePickupPosItem,
            platformName,
            editProps: { editable: true, onOpenModal }
          }} />
        </section>
        <Footer {...{ count: unIntegratedItemCount, onNext: () => onNext(statePosItems), onPrev }} />
      </div>
    </>
  );
};

const WarningView = ({ count }: { count: number }) =>
  count > 0
    ? <div className={styles.warning}><p>連携されていないメニュー({count}点)があります。</p></div>
    : null;

const Footer = ({ count, onNext, onPrev }: { count: number, onNext: () => void, onPrev: () => void }) =>
  <footer>
    <SkeltonButton title="戻る" onClick={() => onPrev()} />
    <div className={styles.initialFooter}>
      <WarningView {...{ count }} />
      <PrimaryButton size='small' title="完了" onClick={() => onNext()} />
    </div>
  </footer>;
