import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Input, PlatformSummaryView, PrimaryButton } from '@/components';
import { Store } from '@/entities';
import styles from '@/styles/partials/forms/StoreForm.module.scss';

type FormData = Pick<Store, 'name' | 'email'>;

interface Props {
  onNext: (_: FormData) => void;
}

export const StoreForm: React.FC<Props> = ({ onNext }: Props) => {
  const { handleSubmit, control, errors } = useForm({ reValidateMode: 'onChange' });

  return (
    <div className={styles.form}>
      <section>
        <PlatformSummaryView />
        <h2>店舗の情報を入力してください</h2>
        <form onSubmit={handleSubmit(onNext)}>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            rules={{ required: '店舗名を入力してください' }}
            render={({ onChange, value }) => (
              <Input
                title="店舗名"
                description="※Orderlyの管理画面上で表示される名称です。店舗名であることがわかるような名称をおすすめします"
                type="text"
                placeholder=""
                onChange={onChange}
                value={value}
                message={errors.name && errors.name.message}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              pattern: {
                value: /[\w\-._+]+@[\w\-._]+\.[A-Za-z]+/,
                message: 'メールアドレスの形式が正しくありません',
              },
            }}
            render={({ onChange, value }) => (
              <Input
                title="店舗担当者のメールアドレス"
                description="※ID/PASSの通知を受け取るために使用されます"
                type="email"
                placeholder=""
                onChange={onChange}
                value={value}
                message={errors.email && errors.email.message}
              />
            )}
          />
        </form>
      </section>
      <footer>
        <div/>
        <PrimaryButton size='small' title="次へ" onClick={handleSubmit(onNext)} />
      </footer>
    </div>
  );
};
