import { PieChart, Spinner } from '@/components';
import { StatisticsChart } from '@/entities';
import styles from '@/styles/partials/dashboards/OrganizationView.module.scss';
import React from 'react';

interface OrganizationViewProps {
  statisticsChart?: StatisticsChart;
  loading: boolean;
}

export const OrganizationView: React.FC<OrganizationViewProps> = ({ statisticsChart, loading }: OrganizationViewProps) => {
  const platformData =
    statisticsChart?.earnings.platform.reduce(
      (acc: { groupName: string; value: number }[], pl) => [
        ...acc,
        { groupName: pl.name, value: pl.data.reduce((acc, a) => acc + a.v, 0) },
      ],
      [],
    ) ?? [];

  const brandData =
    statisticsChart?.earnings.brand.reduce(
      (acc: { groupName: string; value: number }[], pl) => [
        ...acc,
        { groupName: pl.name, value: pl.data.reduce((acc, a) => acc + a.v, 0) },
      ],
      [],
    ) ?? [];

  return (
    <div className={styles.wrapper}>
      {
        loading
          ? <Spinner loading size='contain'/>
          : <><div className={styles.header}>
            <p>売上構成</p>
          </div><div className={styles.titles}>
            <div className={styles.title}>プラットフォーム別</div>
            <div className={styles.title}>ブランド別</div>
          </div><div className={styles.charts}>
            <div className={styles.chart}>
              <PieChart data={platformData} />
            </div>
            <div className={styles.chart}>
              <PieChart data={brandData} />
            </div>
          </div></>
      }

    </div>
  );
};
