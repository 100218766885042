import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { Input, PrimaryButton, Select } from '@/components';
import { PosTypeDecorator } from '@/decorators';
import styles from '@/styles/partials/forms/pos/AccountForm.module.scss';
import { PosPlatformType, PosPlatformTypes } from '@/entities';

type FormData =
  | { type: 'linkto', username: string, password: string }
  | { type: 'ngp', businessCode: string, storeCode: string }
  | { type: 'stesso', username: string, password: string };

interface Props {
  onNext: (data: FormData) => void;
}
type Input = { name: string, title: string, inputType: 'text' | 'password' | 'email', placeholder: string };

export const AccountForm: React.FC<Props> = (props: Props) => {
  const { handleSubmit, control, errors, watch } = useForm({ reValidateMode: 'onChange' });
  const watchType = watch('type', 'stesso');

  const options = PosPlatformTypes.map(value => ({ value, label: new PosTypeDecorator(value).name() }));

  // eslint-disable-next-line react/display-name
  const renderInput = ({ name, title, inputType: type, placeholder }: Input) => ({ value, onChange }: ControllerRenderProps) =>
    <Input {...{ title, type, placeholder, value, message: errors[name]?.message, onChange }} />

  const onSubmit = (data: FormData) => {
    props.onNext(data);
  };

  return <div className={styles.form}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <section>
        <h2>POSのアカウント情報を入力してください</h2>
        <Controller
          name="type"
          control={control}
          defaultValue={'stesso'}
          rules={{ required: 'POSを選択してください' }}
          render={({ onChange, value }) => <Select {...{ title: 'POS選択', options, onChange, value }} />} />
        {inputsFor(watchType).map(({ name, title, inputType, placeholder }) =>
          <Controller
            key={`accountForm-input-${title}`}
            {...{ name, control, rules: { required: `${title}を入力してください` } }}
            render={renderInput({ name, title, inputType, placeholder })} />
        )}
      </section>
      <footer>
        <div />
        <PrimaryButton size='small' title="次へ" onClick={handleSubmit(onSubmit)} />
      </footer>
    </form>
  </div>;
};

const inputsFor = (type: PosPlatformType): Input[] => ({
  'linkto': [
    { name: 'username', title: '契約番号', inputType: 'text', placeholder: '' } as const,
    { name: 'password', title: '認証キー', inputType: 'password', placeholder: 'password' } as const,
  ],
  'ngp': [
    { name: 'businessCode', title: '企業コード', inputType: 'text', placeholder: '' } as const,
    { name: 'storeCode', title: '店舗コード', inputType: 'text', placeholder: '' } as const,
  ],
  'stesso': [
    { name: 'username', title: 'メールアドレス', inputType: 'email', placeholder: 'email@example.com' } as const,
    { name: 'password', title: 'パスワード', inputType: 'password', placeholder: 'password' } as const,
  ]
})[type];
